
export const environment = {
  production: true,
  errorCacheTime: 60,
  backend: 'https://service.colegioexphadis.com.pe',
  supportWeb: 'https://service.colegioexphadis.com.pe',
  sentry: 'https://89c5b49758aa5ed3972b4637cd73bdf4@o368787.ingest.us.sentry.io/4508456274821120',
  key: 'OjxVS62f2W05*I%',
  firebaseConfig: {
    apiKey: 'AIzaSyD6mxgKUP7IMJDUWTckpKHS7l4-iRPZht0',
    authDomain: 'exphadis-fe242.firebaseapp.com',
    databaseURL: 'https://exphadis-fe242.firebaseio.com',
    projectId: 'exphadis-fe242',
    storageBucket: 'exphadis-fe242.appspot.com',
    messagingSenderId: '633488967530',
    appId: '1:633488967530:web:3a7e4beda8bfdd7375c713',
    measurementId: 'G-J5MRNQY949'
  }
};
