import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'snackbar-copy',
  template: `
    <div class="snackbar-container">
      <span class="message">{{ data.message }}</span>
      <button
        mat-raised-button
        color="accent"
        class="copy-button"
        (click)="copyToClipboard()"
      >
        <mat-icon>content_copy</mat-icon> COPIAR
      </button>
    </div>
  `,
  styles: [
    `
      .snackbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .message {
        flex: 1;
        margin-right: 12px;
        font-size: 14px;
      }

      .copy-button {
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 4px 12px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        transition: background-color 0.2s ease;
      }

      .copy-button:hover {
        background-color: #ffb300; /* slightly lighter accent color */
      }

      mat-icon {
        font-size: 18px;
      }
    `,
  ],
})
export class SnackbarCopyComponent {
  @Input() message!: string;

  constructor(
    private snackBarRef: MatSnackBarRef<SnackbarCopyComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { message: string; textToCopy: string },
  ) {}

  copyToClipboard(): void {
    navigator.clipboard
      .writeText(this.data.textToCopy)
      .then(() => {
        console.info('Se copió el texto al portapapeles');
        this.snackBarRef.dismiss();
      })
      .catch((err) => {
        console.error('Clipboard write failed:', err);
      });
  }
}
