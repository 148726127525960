import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { ModalDateRangeOptionComponent } from './modal-date-range-option.component';

@NgModule({
  declarations: [ModalDateRangeOptionComponent],
  imports: [CommonModule, FurySharedModule],
})
export class ModalDateRangeOptionModule {}
