import * as Sentry from '@sentry/browser';
import { Enums } from 'src/app/helper/enums';
import type { CaptureContext } from '@sentry/types';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { CustomFunction } from 'src/app/helper/custom-function';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { SnackbarCopyComponent } from 'src/app/layout/snackbar-copy/snackbar-copy.component';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private recentErrors: Set<string> = new Set();
  private errorCacheTime: number = +`${environment.errorCacheTime * 1000}`;

  constructor(
    private snackbar: MatSnackBar,
    private maintenanceService: MaintenanceService,
    private authenticationService: AuthenticationService,
  ) {}

  handleError(error: any): void {
    const errorString = this.stringifyError(error);

    if (this.recentErrors.has(errorString)) {
      return;
    }

    this.recentErrors.add(errorString);
    this.cleanupCache();

    if (environment.production) {
      let information = {};
      if (this.isValidErrorMessage(error)) {
        information = {
          level: Enums.LogType.ERROR,
          timeStamp: CustomFunction.getCurrentTimeStamp(),
          message: error.message,
          stack: error.stack,
          appName: 'Exphadis Intranet',
        };
      } else {
        information = {
          level: Enums.LogType.ERROR,
          timeStamp: CustomFunction.getCurrentTimeStamp(),
          message: errorString,
          stack: '',
          appName: 'Exphadis Intranet',
        };
      }

      this.maintenanceService.addLog(information).subscribe({
        next: (identifier: string) => {
          this.sendToSentry(error, identifier);

          this.snackbar.openFromComponent(SnackbarCopyComponent, {
            duration: 10000,
            data: {
              message: `Ocurrió un error en el sistema, contacte con sistemas con el siguiente código: '${identifier}'. Puede presionar el botón para copiar este código y compartirlo.`,
              textToCopy: `Comparto el código de error generado para una mayor revisión: '${identifier}'`,
            },
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        },
      });
    }

    CustomFunction.addToConsole('handleError:', error, true);
  }

  private isValidErrorMessage(error: any): error is Error {
    return (
      typeof error === 'object' &&
      error !== null &&
      typeof error.message === 'string' &&
      typeof error.stack === 'string'
    );
  }

  private stringifyError(error: any): string {
    if (error instanceof Error) {
      return `${error.name}: ${error.message}\n${error.stack}`;
    }
    return JSON.stringify(error);
  }

  private cleanupCache(): void {
    setTimeout(() => {
      this.recentErrors.clear();
    }, this.errorCacheTime);
  }

  private sendToSentry(error: any, identifier: string) {
    const context: CaptureContext = {
      level: 'error',
      user: {
        ip_address: this.authenticationService.getIp(),
        username: this.authenticationService.getUsername(),
        email: this.authenticationService.getCompleteName(),
      },
      tags: {
        application_name: 'Exphadis Intranet',
        session_token: this.authenticationService.getToken(),
        identifier: identifier,
      },
    };

    Sentry.configureScope((scope) => {
      scope.setUser({
        username: this.authenticationService.getUsername(),
        email: this.authenticationService.getCompleteName(),
      });
    });

    Sentry.captureException(error.originalError || error, context);
  }
}
