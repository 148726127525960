import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { SnackbarCopyComponent } from './snackbar-copy.component';
import { MaterialModule } from 'src/@fury/shared/material-components.module';

@NgModule({
  declarations: [SnackbarCopyComponent],
  exports: [SnackbarCopyComponent],
  imports: [CommonModule, MaterialModule, FurySharedModule],
})
export class SnackbarCopyModule {}
