<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <div>Confirmar Información</div>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="fury-list-header" fxLayout="row" fxLayoutAlign="start baseline">
    <div
      fxLayout="column"
      fxLayoutAlign="start baseline"
      fxFlex="grow"
      fxLayout.gt-xs="row"
    >
      <mat-form-field fxFlex="grow">
        <mat-label>Fecha Inicio</mat-label>
        <input
          onCopy="return false"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
          autocomplete="off"
          required
          formControlName="startDate"
          matInput
          (keypress)="dateTimeValidation($event)"
          [matDatepicker]="datepickerStartDateRef"
          placeholder="Fecha inicio"
        />
        <mat-datepicker-toggle matSuffix [for]="datepickerStartDateRef">
        </mat-datepicker-toggle>
        <mat-datepicker #datepickerStartDateRef></mat-datepicker>
        <mat-hint align="end">DD/MM/AAAA</mat-hint>
        <mat-error *ngIf="formGroup.get('startDate').hasError('required')">
          Indicar la fecha de inicio
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="grow">
        <mat-label>Fecha Fin</mat-label>
        <input
          onCopy="return false"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
          autocomplete="off"
          required
          formControlName="endDate"
          matInput
          (keypress)="dateTimeValidation($event)"
          [matDatepicker]="datepickerEndDateRef"
          placeholder="Fecha Fin"
        />
        <mat-datepicker-toggle matSuffix [for]="datepickerEndDateRef">
        </mat-datepicker-toggle>
        <mat-datepicker #datepickerEndDateRef></mat-datepicker>
        <mat-hint align="end">DD/MM/AAAA</mat-hint>
        <mat-error *ngIf="formGroup.get('endDate').hasError('required')">
          Indicar la fecha de fin
        </mat-error>
      </mat-form-field>

      <button mat-button>
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</form>
