import * as moment from 'moment';
import { Enums } from 'src/app/helper/enums';
import { Message } from 'src/app/helper/message';
import { Constant } from 'src/app/helper/constant';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'exphadis-modal-date-range-option',
  templateUrl: './modal-date-range-option.component.html',
})
export class ModalDateRangeOptionComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public information: any,
    private dialogRef: MatDialogRef<ModalDateRangeOptionComponent>,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  public onSubmit() {
    if (this.formGroup.status === Enums.FormStatus.INVALID) {
      this.snackbar.open(Message.FillInformationForSearching, null, {
        duration: Constant.DefaultMessageDuration,
      });

      return;
    }

    let startDate: Date;
    let endDate: Date;

    if (moment.isMoment(this.formGroup.controls.startDate.value)) {
      startDate = this.formGroup.controls.startDate.value.toDate();
    }

    if (moment.isMoment(this.formGroup.controls.endDate.value)) {
      endDate = this.formGroup.controls.endDate.value.toDate();
    }

    if (!startDate || !endDate) {
      this.snackbar.open('No se ha ingresado un rango de fechas válido', null, {
        duration: Constant.DefaultMessageDuration,
      });
    }

    const object = {
      startDate: startDate,
      endDate: endDate,
    };

    this.dialogRef.close(object);
  }

  private initializeForm() {
    this.formGroup = this.fb.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });

    if (this.information.notClosable) {
      this.dialogRef.disableClose = true;
      this.dialogRef.backdropClick().subscribe((_) => {});
    }
  }
}
