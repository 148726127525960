import { NgModule } from '@angular/core';
import { Enums } from './helper/enums';
import { AuthGuard } from './core/guard/auth-guard';
import { LayoutComponent } from './layout/layout.component';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: Enums.SitePath.DEFAULT,
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
    pathMatch: 'full',
  },
  {
    path: Enums.SitePath.NOT_FOUND,
    loadChildren: () =>
      import('./layout/not-found/not-found.module').then(
        (m) => m.NotFoundModule,
      ),
  },
  // {
  //   path: Enums.SitePath.FORGOT_PASSWORD,
  //   loadChildren: () =>
  //     import(
  //       './pages/authentication/forgot-password/forgot-password.module'
  //     ).then((m) => m.ForgotPasswordModule),
  //   pathMatch: 'full',
  // },
  {
    path: Enums.SitePath.MAINTENANCE,
    loadChildren: () =>
      import('./layout/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule,
      ),
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: Enums.SitePath.DASHBOARD,
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
        pathMatch: 'full',
      },

      {
        path: Enums.SitePath.STUDENT_LIST,
        loadChildren: () =>
          import('./pages/student/student.module').then((m) => m.StudentModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.STUDENT_REGISTER,
        loadChildren: () =>
          import('./pages/student/register/register.module').then(
            (m) => m.RegisterModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.STUDENT_PAYMENT,
        loadChildren: () =>
          import('./pages/payment/student/payment/payment.module').then(
            (m) => m.StudentPaymentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.CONFIGURATION_PROMOTION,
        loadChildren: () =>
          import('./pages/configuration/configuration.module').then(
            (m) => m.ConfigurationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.CONFIGURATION_PAYMENT,
        loadChildren: () =>
          import('./pages/configuration/configuration.module').then(
            (m) => m.ConfigurationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.CONFIGURATION_AREA,
        loadChildren: () =>
          import('./pages/configuration/configuration.module').then(
            (m) => m.ConfigurationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.CONFIGURATION_UNITY,
        loadChildren: () =>
          import('./pages/configuration/configuration.module').then(
            (m) => m.ConfigurationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.INFORM,
        loadChildren: () =>
          import('./pages/information/information.module').then(
            (m) => m.InformationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.INFORM_STAGE,
        loadChildren: () =>
          import('./pages/information/information.module').then(
            (m) => m.InformStateModule,
          ),
        pathMatch: 'full',
      },
      // {
      //   path: Enums.SitePath.CONFIGURATION_PROMOTION,
      //   loadChildren: () =>
      //     import('./pages/configuration/promotion/promotion.module').then(
      //       (m) => m.PromotionConfigurationModule,
      //     ),
      //   pathMatch: 'full',
      // },
      // {
      //   path: Enums.SitePath.CONFIGURATION_UNITY,
      //   loadChildren: () =>
      //     import('./pages/configuration/unity/unity.module').then(
      //       (m) => m.UnityModule,
      //     ),
      //   pathMatch: 'full',
      // },
      // {
      //   path: Enums.SitePath.CONFIGURATION_AREA,
      //   loadChildren: () =>
      //     import('./pages/configuration/area/area.module').then(
      //       (m) => m.AreaModule,
      //     ),
      //   pathMatch: 'full',
      // },
      {
        path: Enums.SitePath.STUDENT_PROMOTION,
        loadChildren: () =>
          import('./pages/payment/student/promotion/promotion.module').then(
            (m) => m.PromotionStudentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.ENROLLMENT,
        loadChildren: () =>
          import('./pages/enrollment/enrollment.module').then(
            (m) => m.EnrollmentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.COURSE,
        loadChildren: () =>
          import('./pages/course/course/course.module').then(
            (m) => m.CourseModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.COMPETENCE,
        loadChildren: () =>
          import('./pages/course/competence/competence.module').then(
            (m) => m.CompetenceModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.COURSE_COMPETENCE_CRITERIA,
        loadChildren: () =>
          import('./pages/course/criteria/criteria.module').then(
            (m) => m.CriteriaModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKER_LIST,
        loadChildren: () =>
          import('./pages/worker/worker.module').then((m) => m.WorkerModule),
        pathMatch: 'full',
      },

      {
        path: Enums.SitePath.WORKER_EVALUATION,
        loadChildren: () =>
          import('./pages/evaluation/evaluation.module').then(
            (m) => m.EvaluationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKER_ASSISTANCE,
        loadChildren: () =>
          import('./pages/worker/assistance/assistance.module').then(
            (m) => m.AssistanceModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKER_ASSISTANCE_DETAIL,
        loadChildren: () =>
          import(
            './pages/worker/assistance/assistance-detail/assistance-detail.module'
          ).then((m) => m.AssistanceDetailModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKER_CONTRACT,
        loadChildren: () =>
          import('./pages/worker/contract/contract.module').then(
            (m) => m.ContractModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKER_TICKET,
        loadChildren: () =>
          import('./pages/worker/ticket/ticket.module').then(
            (m) => m.TicketModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.FIND_OPERATION_NUMBER,
        loadChildren: () =>
          import(
            './pages/payment/personal/find-operation-number/find-operation-number.module'
          ).then((m) => m.FindOperationNumberModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.SUBJECT,
        loadChildren: () =>
          import('./pages/subject/subject.module').then((m) => m.SubjectModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.STUDENT_LIST_PAYMENT,
        loadChildren: () =>
          import('./pages/payment/student/payment/list/list.module').then(
            (m) => m.ListStudentPaymentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.TUTORSHIP,
        loadChildren: () =>
          import('./pages/tutorship/tutorship.module').then(
            (m) => m.TutorshipModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.RECOMMENDATION,
        loadChildren: () =>
          import('./pages/recommendation/recommendation.module').then(
            (m) => m.RecommendationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.RECOMMENDATION_STUDENT,
        loadChildren: () =>
          import('./pages/recommendation/recommendation.module').then(
            (m) => m.RecommendationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.STUDENT_GRADE,
        loadChildren: () =>
          import('./pages/student/enrollment/enrollment.module').then(
            (m) => m.EnrollmentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.STUDENT_ATTORNEY,
        loadChildren: () =>
          import('./pages/student/attorney/attorney.module').then(
            (m) => m.AttorneyModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.ASSIGNED_COMPETENCE,
        loadChildren: () =>
          import(
            './pages/course/competence-selected/competence-selected.module'
          ).then((m) => m.CompetenceSelectedModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.ASSIGNED_ADD_COMPETENCE,
        loadChildren: () =>
          import(
            './pages/course/competence-selected/competence/competence.module'
          ).then((m) => m.CompetenceToSelectModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.USER_MANAGEMENT,
        loadChildren: () =>
          import('./pages/user/user.module').then((m) => m.UserModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKSHOP,
        loadChildren: () =>
          import('./pages/workshop/workshop.module').then(
            (m) => m.WorkshopModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKSHOP_INSCRIPTION,
        loadChildren: () =>
          import('./pages/workshop/inscription/inscription.module').then(
            (m) => m.InscriptionModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.BANK_REVISION,
        loadChildren: () =>
          import('./pages/payment/bank-revision/bank-revision.module').then(
            (m) => m.BankRevisionModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.BANK_REVISION_DETAIL,
        loadChildren: () =>
          import('./pages/payment/bank-revision/detail/detail.module').then(
            (m) => m.BankRevisionDetailModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.WORKSHOP_FOR_STUDENT,
        loadChildren: () =>
          import('./pages/workshop/attorney-list/attorney-list.module').then(
            (m) => m.AttorneyListModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.PSYCHOLOGY,
        loadChildren: () =>
          import('./pages/psychology/psychology.module').then(
            (m) => m.PsychologyModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.APPOINTMENT,
        loadChildren: () =>
          import('./pages/psychology/appointment/appointment.module').then(
            (m) => m.AppointmentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.EVALUATION_DETAIL,
        loadChildren: () =>
          import('./pages/evaluation/detail/detail.module').then(
            (m) => m.DetailModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.REPORT,
        loadChildren: () =>
          import('./pages/report/report.module').then((m) => m.ReportModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.MEDIA_MANAGEMENT,
        loadChildren: () =>
          import('./layout/media-management/media-management.module').then(
            (m) => m.MediaManagementModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.PAYMENT_COMMITMENT,
        loadChildren: () =>
          import('./pages/payment/student/commitment/commitment.module').then(
            (m) => m.CommitmentModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.QUALIFICATION_COGNITIVE,
        loadChildren: () =>
          import('./pages/qualification/cognitive/cognitive.module').then(
            (m) => m.CognitiveModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.QUALIFICATION_CONDUCT,
        loadChildren: () =>
          import('./pages/qualification/conduct/conduct.module').then(
            (m) => m.ConductModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.QUALIFICATION_PROCEDURAL_AND_ATTITUDINAL,
        loadChildren: () =>
          import(
            './pages/qualification/attitudinal-procedural/attitudinal-procedural.module'
          ).then((m) => m.AttitudinalProceduralModule),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.ANNOUNCEMENT,
        loadChildren: () =>
          import('./pages/announcement/announcement.module').then(
            (m) => m.AnnouncementModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.NOTIFICATION,
        loadChildren: () =>
          import('./pages/notification/notification.module').then(
            (m) => m.NotificationModule,
          ),
        pathMatch: 'full',
      },
      {
        path: Enums.SitePath.EGRESS,
        loadChildren: () =>
          import('./pages/payment/egress/egress.module').then(
            (m) => m.EgressModule,
          ),
        pathMatch: 'full',
      },
    ],
  },
  {
    path: Enums.SitePath.ALL,
    redirectTo: Enums.SitePath.NOT_FOUND,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
