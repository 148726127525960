import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Enums } from 'src/app/helper/enums';
import { Message } from 'src/app/helper/message';
import { Constant } from 'src/app/helper/constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CustomFunction } from 'src/app/helper/custom-function';
import { GenericOption } from 'src/app/model/base/generic-option';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HealthCheck } from 'src/app/model/master-data/health-check';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { fadeInUpAnimation } from 'src/@fury/animations/fade-in-up.animation';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { LoginInformation } from 'src/app/model/authentication/login-Information';

@Component({
  selector: 'fury-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInUpAnimation],
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  inputType = 'password';
  visible = false;
  checked: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private maintenanceService: MaintenanceService,
    private angularFireAuth: AngularFireAuth,
    private authenticationService: AuthenticationService,
  ) {}

  public ngOnInit() {
    this.initializeForm();

    forkJoin([
      this.maintenanceService.getHealthCheck(),
      this.maintenanceService.getConfigurationVariables(),
    ]).subscribe(([healthCheck, configurationVariables]) => {
      const isHealthy = this.validateHealthCheck(healthCheck);

      if (!isHealthy) {
        return;
      }

      const allowToContinue = this.manageConfigurationVariables(
        configurationVariables,
      );

      if (!allowToContinue) {
        return;
      }

      const tokenIsValid = this.authenticationService.tokenIsValid();

      if (tokenIsValid) {
        const loginInformation = new LoginInformation();
        loginInformation.username = this.authenticationService.getUsername();
        loginInformation.password = this.authenticationService.getPassword();
        loginInformation.ip = this.authenticationService.getIp();
        loginInformation.applicationClient = 'WEB INTRANET EXPHADIS';

        if (loginInformation.password) {
          this.authenticationService
            .login(loginInformation)
            .toPromise()
            .then(() => {
              this.makeHardReloading();
            });
        } else {
          const loginInformation = new LoginInformation();
          loginInformation.completeName =
            this.authenticationService.getCompleteName();
          loginInformation.email = this.authenticationService.getEmail();
          loginInformation.ip = this.authenticationService.getIp();
          loginInformation.applicationClient = 'WEB INTRANET EXPHADIS';

          this.authenticationService
            .loginWithThirdParty(loginInformation)
            .toPromise()
            .then(() => {
              this.makeHardReloading();
            });
        }

        return;
      }

      const username = this.authenticationService.getUsername();

      this.setRememberMeValues();

      this.checked = username !== null && username !== '';
    });
  }

  public textOnly = (event: KeyboardEvent) =>
    CustomFunction.textOnly(event.key);

  // #region EVENT BUTTONS

  public onSubmit() {
    if (this.formGroup.status === Enums.FormStatus.INVALID) {
      this.snackbar.open(Message.ItsNecessaryUsernameAndPassword, null, {
        duration: Constant.DefaultMessageDuration,
      });

      return;
    }

    const loginInformation = new LoginInformation();
    loginInformation.username = this.formGroup.controls.user.value;
    loginInformation.password = this.formGroup.controls.password.value;
    loginInformation.ip = this.authenticationService.getIp();
    loginInformation.applicationClient = 'WEB INTRANET EXPHADIS';

    this.authenticationService
      .login(loginInformation)
      .toPromise()
      .then(() => {
        this.makeHardReloading();
      });
  }

  public loginWithGoogle = () =>
    this.angularFireAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(
        (result) => {
          const loginInformation = new LoginInformation();
          loginInformation.completeName = result.user.displayName;
          loginInformation.email = result.user.email;
          loginInformation.ip = this.authenticationService.getIp();
          loginInformation.applicationClient = 'WEB INTRANET EXPHADIS';

          this.authenticationService
            .loginWithThirdParty(loginInformation)
            .toPromise()
            .then(() => {
              this.makeHardReloading();
            });
        },
        (error) => {
          this.snackbar.open(
            'Ocurrio un error al iniciar sesión con google',
            null,
            {
              duration: Constant.DefaultMessageDuration,
            },
          );

          throw Error(error);
        },
      );

  public rememberMe(event: MatCheckboxChange) {
    if (event.checked) {
      const now = new Date();

      this.authenticationService.setLoginCookies(
        this.formGroup.controls.user.value,
        this.formGroup.controls.password.value,
        new Date(now.setDate(now.getDate() + 7)),
      );
    } else {
      this.authenticationService.clearLoginCookies();
      this.formGroup.controls.user.setValue(null);
      this.formGroup.controls.password.setValue(null);
    }
  }

  public allowRememberMe(event: PointerEvent) {
    if (this.formGroup.status === Enums.FormStatus.INVALID) {
      this.snackbar.open(
        Message.DebeIngresarUsuarioPasswordParaRecordar,
        null,
        {
          duration: Constant.DefaultMessageDuration,
        },
      );

      event.preventDefault();
    }
  }

  public toggleVisibility() {
    this.inputType = this.visible === true ? 'password' : 'text';
    this.visible = !this.visible;
    this.cd.markForCheck();
  }

  // #region

  // #region PRIVATE

  private makeHardReloading() {
    const userInformation = this.authenticationService.getUserInformation();

    if (userInformation !== null) {
      const information = {
        userId: this.authenticationService.getUserInformation().userId,
        timeStamp: CustomFunction.getCurrentTimeStamp(),
        ip: this.authenticationService.getIp(),
        Browser: this.authenticationService.getBrowser(),
      };

      this.maintenanceService.getHardReloadingCount(information).subscribe({
        next: (data: number) => {
          if (data === 0) {
            this.maintenanceService.addHardReloading(information).subscribe({
              next: () => {
                window.location.reload();
              },
            });
          }
        },
      });
    }
  }

  private validateHealthCheck(data: HealthCheck): boolean {
    if (!data) {
      this.router.navigate([Enums.SitePath.MAINTENANCE]).then();
      return false;
    }

    if (data.status !== 'Healthy') {
      this.router.navigate([Enums.SitePath.MAINTENANCE]).then();
      return false;
    }

    return true;
  }

  private manageConfigurationVariables(variables: GenericOption[]): boolean {
    for (let i in variables) {
      const variable = variables[+i];

      let value: any;
      switch (variable.configurationVariableType) {
        case Enums.ConfigurationVariableType.Maintenance:
          value = variable.value === true;

          if (value) {
            this.router.navigate([Enums.SitePath.MAINTENANCE]).then();
            return false;
          }
          break;
        case Enums.ConfigurationVariableType.CleanLocalStorage:
          value = variable.value === true;

          if (value) {
            CustomFunction.clearStorage();
          }
          break;
        case Enums.ConfigurationVariableType.Test:
          value = variable.value;

          CustomFunction.addToConsole(`manageConfigurationVariables: ${value}`);
          break;
      }
    }

    return true;
  }

  private initializeForm() {
    this.formGroup = this.fb.group({
      user: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  private setRememberMeValues() {
    const username: string = this.authenticationService.getUsername();
    const password: string = this.authenticationService.getPassword();

    if (username && password) {
      this.formGroup.controls.user.setValue(username);
      this.formGroup.controls.password.setValue(password);
    }
  }

  // #region
}
