import { DatePipe } from '@angular/common';
import { Enums } from 'src/app/helper/enums';
import { Message } from 'src/app/helper/message';
import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/helper/constant';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomFunction } from 'src/app/helper/custom-function';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'exphadis-modal-date-range-option',
  templateUrl: './modal-date-range-option.component.html',
})
export class ModalDateRangeOptionComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  public onSubmit() {
    if (this.formGroup.status === Enums.FormStatus.INVALID) {
      this.snackbar.open(Message.FillInformationForSearching, null, {
        duration: Constant.DefaultMessageDuration,
      });

      return;
    }

    // this.filterEnableToShowSummary = true;
    // this.resetDataSource();

    // this.searchPaymentByDateRangeService();
  }

  public dateTimeValidation = (event: KeyboardEvent) =>
    CustomFunction.dateTimeValidation(event.key);

  private initializeForm() {
    this.formGroup = this.fb.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });
  }
}
